import React from 'react';
import image1 from '../img/man (1).png'
import { Link } from 'react-router-dom';


const TableComponent = ({ data }) => (
   
<tbody>
{data.length > 0 ? (
    data.map((item, index) => (
        <tr className='bg-white border-b text-black ' key={index}>
       
            <td className="px-6 py-4">{item.phonenumber}</td>
            <td className="px-6 py-4">{item.amount}</td>
            <td className="px-6 py-4">{item.merchantId}</td>
            <td className="px-6 py-4">{item.description}</td>
            <td className="px-6 py-4">{item.insertedDt}</td>
            <td className="px-6 py-4">{item.merchantThriftId}</td>
            <td className="px-6 py-4">{item.userThriftId}</td>   
        </tr>
    ))
) : (
    <tr className='mt-9'>
    <td colSpan="4" className='text-center text-1xl '>
        No Customer Available
    </td>
</tr>
)}
</tbody>
);

export default TableComponent;
