/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import axiosInstance from '../utils/axios';
import { toast } from "react-toastify";
const Modal = ({toggleModal, isOpen, id}) => {

    async function DeleteHandler(id){
        try {
            const response = await axiosInstance.get(`delete_merchant_customer?phoneNumber=${id}`);
           if(response.data.result.status_code === '0'){
            toast.success(response.data.message);
           }
           else{
            toast.error(response.data.message);
  
            window.location.href = window.location.href;
location.reload(true);
           }
           
        } catch (error) {
            // Handle error
        }
       
    }
  return (
    <>
    

      {isOpen && (
        <div id="popup-modal" tabIndex={-1} className="fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow w-full max-w-md p-4 md:p-5 text-center">
            <button onClick={toggleModal} type="button" className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-4 md:p-5">
              <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500">Are you sure you want to delete this group?</h3>
              <button onClick={() => DeleteHandler(id)} type="submit" className="text-white bg-zippy hover:bg-zippy focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                Yes, I'm sure
              </button>
              <button onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100">No, cancel</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

