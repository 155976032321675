import React from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/side_bar';
import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axios';
import { useAuth } from '../store/Authprovider';
import image1 from '../img/man (1).png'
import image2 from '../img/customer01.jpg'
import { NavLink } from 'react-router-dom';
const Viewmore = () => {
  const { id } = useParams()
  const data = id;
  const { user } = useAuth();
  const [userdetail, setuserdetail] = useState({});

 

  useEffect(() => {
    const fetchData = async () => {
        try {
            if (!data) {
                return;
            }
            const response1 = await axiosInstance.get(
              `single_merchant_customer?phoneNumber=${data}&merchantId=${user.merchantId}`
      
          );
          setuserdetail(response1.data.result);

          

        } catch (error) {
            console.error("Error fetching data:", error);

        
    }
}

    fetchData();
}, [data]);
 
    const [isActive, setIsActive] = useState(false);
  function toggleMenu() {
    setIsActive(!isActive);
  }
    return (
        <>
<div className="containers">
      <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
      <div className={`main ${isActive ? "active" : ""}`}>
        <div className="topbar">
          <div className="toggle" onClick={toggleMenu}>
            <ion-icon name="menu-outline" />
          </div>

          <div className="search">
            <label>
              <input type="text" placeHolder="Search here" />
              <ion-icon name="search-outline" />
            </label>
          </div>

          <div className="user">
            <img src={image2} alt="" />
          </div>
        </div>

        {/* ================ Order Details List ================= */}
        <div className="detail">
     
        <div className="w-full   border border-gray-200 rounded-lg shadow ">
        <div className="flex justify-end px-4 pt-4">
        <NavLink to="/merchant_customer"  exact> <button className="text-white bg-zippy p-2 rounded-lg">Back</button></NavLink> 
        </div>
        <div className="flex flex-col items-center 
        
        ">
            <img className="w-14 h-14 mb-3 rounded-full shadow-lg" src={image1} alt="Bonnie image" />
    
        </div>

        <table className="w-full text-sm text-left rtl:text-right text-gray-200 dark:text-gray-400">
       
            <thead className="text-sm text-black uppercase bg-gray-200 font-extrabold ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Names
                </th>
                <th scope="col" className="px-6 py-3">
                  Address
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Date Created
                </th>
           
             
              </tr>
            </thead>
            <tbody>
            <tr class ="text-black">
<td className="px-6 py-3">{userdetail.customerDetails?.firstname || ''} {userdetail.customerDetails?.lastname || ''}</td>    
<td className="px-6 py-3">{userdetail.customerDetails?.address || ''}</td>   
<td className="px-6 py-3">{userdetail.customerDetails?.phoneNumber || ''}</td>   
<td className="px-6 py-3">{userdetail.customerDetails?.dateRegister || ''}</td>   
</tr>        
            </tbody>

            <thead className="text-sm text-black uppercase bg-gray-200 font-extrabold ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No of group
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Savings
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Withdrawal
                </th>
                <th scope="col" className="px-6 py-3">
                  Current balance
                </th>
           
             
              </tr>
            </thead>
            <tbody>
              <tr class ="text-black">
   <td className="px-6 py-3">{userdetail.customerDetails?.noOfGroup || '0.00'}</td>    
   <td className="px-6 py-3">{userdetail.customerDetails?.totalSavings || '0.00'}</td>   
   <td className="px-6 py-3">{userdetail.customerDetails?.totalWithdrawal || '0.00'}</td>   
   <td className="px-6 py-3">{userdetail.customerDetails?.currentBalance || '0.00'}</td>   
</tr>        
            </tbody>


          </table>
    </div>

          {/* ================= New Customers ================ */}
        </div>
      </div>
    </div>




    </>
    );
};

export default Viewmore;

