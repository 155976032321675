
import image1 from '../img/c478d39473214a86415c18369177c2ef.jpeg'


function App() {


    return (
        <>
<div className="relative bg-black text-white min-h-screen flex flex-col justify-end">
  { /* Background image */ }
  <div className="absolute inset-0">
    <img src={image1} alt="Background" className="w-full h-full object-cover" />
  </div>

  { /* Overlay content at the bottom */ }
  <div className="relative z-10 p-8 lg:p-16">
    <div className="flex flex-col md:flex-row justify-between items-end">
      { /* Text container */ }
      <div>
        <h2 className="text-xl md:text-3xl font-semibold mb-3">This striking image has won our Pictures Of The Year photo contest.</h2>
        <p className="mb-5">See all 10 of the top photographs and find out how perseverance, and a ton of patience, got Karthik Subramaniam the grand-prize-winning shot.</p>
      </div>
      
      { /* Button container */ }
      <div>
        <a href="#" className="inline-block px-6 py-3 bg-yellow-500 text-black font-semibold rounded-full hover:bg-yellow-600 transition-colors">Explore</a>
      </div>
    </div>
  </div>
</div>


           
        </>
    );
}

export default App;