import React, { useState } from "react";
import { useAuth } from "../store/Authprovider";
import { Navigate, useLocation } from 'react-router-dom';
import Navlinks from './Navlinks'
import image1 from '../img/zippylogo.png'
import '../style.css';

const Side_bar = ({ isActive }) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation()

 

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const { logout } = useAuth();
  function logoutSubmit() {
    logout();
    <Navigate to="/" />
  }

  return (
    <>
      <div className={`navigation ${isActive ? "active" : ""} overflow-y-auto`}>
        <ul className="oveflow-y-auto">
        <li >
            <a href="#">
              <span className="icon">
                <img src={image1} className=" ml-2 mt-3 h-10 w-10" />
              </span>
              <span className="title">Zippyworld </span>
            </a>
          </li>
         
<Navlinks pathname = '/dashboard' Navlinkname='Dashboard' iconName='home-outline'/>
<Navlinks pathname = '/create_thrift' Navlinkname='Create Group' iconName='people-outline'/>
<Navlinks pathname = '/list_thrift' Navlinkname='List Group' iconName='stats-chart-outline'/>
<Navlinks pathname = '/create_customer' Navlinkname='Create Customer' iconName='person-outline'/>
<Navlinks pathname = '/merchant_customer' Navlinkname='List Customer' iconName='bar-chart-outline'/>
<Navlinks pathname = '/join_group' Navlinkname='Join Group' iconName='walk-outline'/>
<Navlinks pathname = '/collect_funds' Navlinkname='Collect Funds' iconName='card-outline'/>
<Navlinks pathname = '/withdraw_funds' Navlinkname='Withdraw Funds' iconName='wallet-outline'/>
<Navlinks pathname = '/change_password' Navlinkname='Change password' iconName='lock-closed-outline'/>
          <li>
            <a onClick={logoutSubmit}>
              <span className="icon">
                <ion-icon name="log-out-outline" />
              </span>
              <span className="title">Sign Out</span>
            </a>
          </li>

        </ul>
      </div>
    </>
  );
};

export default Side_bar;
