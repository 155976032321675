import React from 'react';
import { useAuth } from '../store/Authprovider';
import { useNavigate } from 'react-router-dom';
import image1 from '../img/zippylogo.png'
import axiosInstance from '../utils/axios';
import { toast } from 'react-toastify';


const Resetpassword = () => {
    const {  user, logout } = useAuth();
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const oldPassword = event.target.opassword.value;
        const newPassword = event.target.npassword.value;
        const confirmPassword = event.target.cpassword.value;
        const email = user.emailAddress
        try {
            if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
                toast.error('Please fill all the fields');
                return;
            }
          
    
            const response = await axiosInstance.post('change_merchant_password', {
                emailAddress: email ,
                previousPassword: oldPassword ,
                newPassword: newPassword ,
                confirmPassword: confirmPassword ,
            });
    
           
    
            if (response.data.status_code === '0') {
                toast.success(response.data.message);
                    navigate('/');
                    logout();

            } else {
                toast.error(response.data.message || 'An error occurred, Contact Admin');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred, Contact Admin');
        }
    };
    





    return (
        <>   <div className="h-screen overflow-hidden flex items-center justify-center" style={{ background: '#edf2f7' }}>


        <div className="bg-purple-900 absolute top-0 left-0 bg-gradient-to-b from-gray-900 via-gray-900 to-zippy bottom-0 leading-5 h-full w-full overflow-hidden">

        </div>
        <div className="relative   min-h-screen  sm:flex sm:flex-row  justify-center bg-transparent rounded-3xl shadow-xl">
            <div className="flex-col flex  self-center lg:px-14 sm:max-w-4xl xl:max-w-md  z-10">
                <div className="self-start hidden lg:flex flex-col  text-gray-300">

                    <h1 className="my-3 font-semibold flex gap-2 text-3xl"><span><img className='h-12' src={image1} /></span> <span className='mt-2'>ZIPPYWORLD</span></h1>
                    <p className="pr-3 text-sm opacity-75">Join our money contribution app to save and grow your money with friends and family. Easy, secure, and rewarding. Start saving together today!</p>
                </div>
            </div>
            <div className="flex justify-center self-center  z-10">

                <div className="p-12 bg-white mx-auto rounded-3xl w-96 ">
                    <div className="mb-7">
                        <h3 className="font-semibold text-2xl text-gray-800">Reset Password </h3>

                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-6">
                            <div className="">
                                <input name='opassword' className=" w-full text-sm  px-4 py-3 bg-gray-200 focus:bg-gray-100 border  border-gray-200 rounded-lg focus:outline-none focus:border-purple-400" type="password" placeholder="Old password" />
                            </div>

                            <div className="">
                                <input name='npassword' className=" w-full text-sm  px-4 py-3 bg-gray-200 focus:bg-gray-100 border  border-gray-200 rounded-lg focus:outline-none focus:border-purple-400" type="password" placeholder="New password" />
                            </div>


                            <div className="relative" x-data="">
                                <input name='cpassword' type='password' placeholder=" Confirm password" className=" w-full text-sm  px-4 py-3 bg-gray-200 focus:bg-gray-100 border  border-gray-200 rounded-lg focus:outline-none focus:border-purple-400" />
                                <div type='' className="flex items-center absolute inset-y-0 right-0 mr-3  text-sm leading-5">





                                </div>
                            </div>


                            <div className="flex items-center justify-between">

                            </div>
                            <div>
                                <button type="submit" className="w-full flex justify-center bg-zippy  hover:bg-zippy text-gray-100 p-3  rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="mt-7 text-center text-zippy text-xs">
                        <span>
                            Copyright © 2024

                        </span></div>
                </div>
            </div>
        </div>

        <footer className="bg-transparent absolute w-full bottom-0 left-0 z-30">
            <div className="container p-5 mx-auto  flex items-center justify-between ">


            </div>
        </footer>

        <svg className="absolute bottom-0 left-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" /></svg>
    </div>
    </>
    );
};

export default Resetpassword;
