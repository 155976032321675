import React from "react";
import Sidebar from "../components/side_bar";
import { useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import axiosInstance from "../utils/axios";
import TableComponent from "../components/table5";
import { useAuth } from "../store/Authprovider";
import image2 from '../img/customer01.jpg'
import { useEffect } from "react";
import PaginationButtons from '../components/paginationbutton';
import usePagination from '../hooks/use-pagination';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Merchant = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams()
  const phoneNumber = id;
  const { user } = useAuth();
  const [transactions, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { currentPage, totalPages, paginatedData, nextPage, prevPage, goToPage } = usePagination(transactions, 6);



  useEffect(() => {
    const fetchData = async () => {
        try {

          
          if (!user || !user.merchantId) {
            // Handle the case when user or user.merchantId is null or undefined
            // For example, set data to an empty array
            setData([]);
            return;
        }
            const response = await axiosInstance.get(
                `customer_transaction?phoneNumber=${phoneNumber}`
            );

            if (searchTerm) {
                const searchResults = response.data.result.filter((item) =>
                Object.values(item).some((value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
                ))
                //Object.values convert result to this [ola, customer]
                //.some((value) check if typeof value === 'string 
                setData(searchResults);
            } else {
                setData(response.data.result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [searchTerm, user.merchantId, axiosInstance]);

const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
};




  const [isActive, setIsActive] = useState(false);
  function toggleMenu() {
    setIsActive(!isActive);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const start_dt = event.target.start_dt.value;
    const end_dt = event.target.end_dt.value;
  
  

      try {
        if (
          start_dt && end_dt === ""
  
        ) {
          toast.error("Please fill all the fields");
          return;
        }
        setIsLoading(true);
        const response = await axiosInstance.post('fetch_customer_transaction', {
          startDate: start_dt,
          endDate: end_dt,
          customerPhoneNumber:phoneNumber
      });
        if (response.data.status_code === "0") {
          setData(response.data.result)
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred, Contact Admin");
      }
      finally{
        setTimeout(() => {
          setIsLoading(false);
      }, 3000);
      }
    
    
   
  };

  return (
    <div className="containers">
      <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
      <div className={`main ${isActive ? "active" : ""}`}>
        <div className="topbar">
          <div className="toggle" onClick={toggleMenu}>
            <ion-icon name="menu-outline" />
          </div>

          <div className="search">
            <label>
              <input placeHolder="Search here"
              name="search"
              type="text"
                value={searchTerm}
                onChange={handleSearchInputChange}
          

              />
              <ion-icon name="search-outline" />
            </label>
          </div>

          <div className="user">
            <img src={image2} alt="" />
          </div>
        </div>

        {/* ================ Order Details List ================= */}
        <div className="detail">

        <form onSubmit={handleSubmit} className="">
              <div className="grid gap-6  md:grid-cols-2">  
                <div>
                  <label
                    htmlFor="address"
                    className="block mb-2 text-md font-extrabold  text-gray-900 "
                  >
                  Start Date
                  </label>
                  <input
                    name="start_dt"
                    type="date"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    placeholder="Input your Otp code"
                  />
                </div>
                <div>
                  <label
                    htmlFor="end_dt"
                    className="block mb-2 text-md font-extrabold  text-gray-900 "
                  >
                  End Date
                  </label>
                  <input
                    name="end_dt"
                    type="date"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    placeholder="Input your Otp code"
                  />
                </div>
              </div>
              

              <div className=""></div>
              <div className=""></div>
              <div className="flex items-start mb-6"></div>
              <button
                                disabled={isLoading}
                                type="submit"
                                className="text-white bg-zippy  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2  inline-flex items-center"
                            >
                                {isLoading ? (
                                    <>
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                        Submitting request...
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </button>
            
            </form>
       
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-white bg-zippy ">

<div className="flex justify-between"><p className="text-white text-2xl">Transactions of {phoneNumber}</p>
<button className="text-white bg-green-500  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2  inline-flex items-center"> <CSVLink data={paginatedData}>Export Csv</CSVLink></button>
{phoneNumber ?<NavLink to="/merchant_customer"  exact>    <h2 className="text-1xl text-white p-1">Back</h2></NavLink> : ''}
      </div> 
</caption>
            <thead className="text-sm text-black uppercase bg-gray-50 font-extrabold ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  MerchantID
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                 insertedDt
                </th>
                <th scope="col" className="px-6 py-3">
                  Merchant Thrift Id
                </th>
                <th scope="col" className="px-6 py-3">
                User Thrift Id
                </th>
           
              </tr>
            </thead>

            <TableComponent data={paginatedData}  />
                            <PaginationButtons
                                currentPage={currentPage}
                                totalPages={totalPages}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
          </table>

          {/* ================= New Customers ================ */}
        </div>
      </div>
    </div>
  );
};

export default Merchant;
