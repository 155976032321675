// import axios from "axios";

// const axiosInstance = axios.create({
//     baseURL: 'http://161.35.56.41/zippy_world_live_api/buz/api/',
//     timeout: 600000,
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'x-api-key': '6012451'
//     }
// });

// export default axiosInstance;


import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://ajo-api.zippyworld.net/zippy_ajo/',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': '09022024'
    }
});

export default axiosInstance;
