import React from 'react';
import image1 from '../img/groups.png';
import { Link } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import  Modal  from '../components/modal';
import  Modalstatus  from '../components/modal_status';



const TableComponent = ({ data, loading }) => {

    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [status, setIsstatus] = useState(false);


    const toggleModal = (id) => {
      setIsOpen(!isOpen);
      setIsstatus(false);
      setId(id);   
    };

    const toggleStatusModal = (id) => {
        setIsOpen(false);
        setIsstatus(!status);
        setId(id);   
      };

    
 
    return (
        <tbody>
                <Modal toggleModal={toggleModal} isOpen={isOpen} id={id} />
                <Modalstatus toggleStatusModal={toggleStatusModal} isOpen={status} id={id} />

            {loading ? (
                <tr>
                    <td colSpan="3" className='text-center text-2xl'>
                        &nbsp;
                    </td>
                </tr>
            ) : data.length > 0 ? (
                data.map((item, index) => (
                    <tr className='bg-white border-b text-black ' key={index}>
                        <td scope="row" className="flex items-center px-6 py-4  whitespace-nowrap">
                            <img className="w-10 h-10 rounded-full" src={image1} alt="Jese image" />
                            <div className="ps-3">
                                <div className="text-base font-bold">{item.groupName}</div>
                            </div>
                        </td>
                        <td className="px-6 py-4">{item.groupId}</td>
                        <td className="px-6 py-4">{item.noOfMembers}</td>
                        <td className="px-6 py-4">N{item.amountReceived}</td>
                        <td className="px-6 py-4">N{item.amountWithdrawal}</td>
                        <td className="px-6 py-4">
                            <span className={"status " + (item.status === "Active" ? "delivered" : "return")}>
                                {item.status}
                            </span>
                        </td>
                        <td className="px-6 py-4">{item.dateCreated}</td>
                        <td className="px-6 py-3">
                           <span className='mr-4 ml-4'> <Link to={`/create_thrift/${item.groupId}`}>
                                <ion-icon name="pencil-outline" />
                            </Link></span>
                            <span className='mr-4 ml-4' > 
                                <ion-icon onClick={() => toggleModal(item.groupId)} name="trash-outline" />
                            </span>
                            <span > 
                                <ion-icon onClick={() => toggleStatusModal(item.groupId)} name="key-outline" />
                            </span>
                            
                        </td>
                    </tr>
                ))
            ) : (
                <tr className='mt-9'>
                    <td colSpan="6" className='text-center text-1xl '>
                        No Group Available
                    </td>
                </tr>
            )}
        </tbody>
        
    );
};

export default TableComponent;
