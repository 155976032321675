import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "../store/Authprovider";

const ProtectedRoute = ({ children }) => {
  const { islogin, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!islogin || (user?.isPasswordChange === '1')) {
      navigate('/');
    }
  }, [islogin, navigate, user?.isPasswordChange]);

  return islogin ? <Outlet /> : null;
};

export default ProtectedRoute;
