import React from 'react';
import image1 from '../img/man (1).png'
import { Link } from 'react-router-dom';


const TableComponent = ({ data }) => (
   
<tbody>
{data.length > 0 ? (
    data.map((item, index) => (
        <tr className='bg-white border-b text-black ' key={index}>
       
            <td className="px-6 py-4">{item.groupDetails.groupName}</td>
            <td className="px-6 py-4">{item.amount}</td>
            <td className="px-6 py-4">{item.amountContributed}</td>
            <td className="px-6 py-4">{item.amountWithdraw}</td>
            <td className="px-6 py-4">{item.defaultCharge}</td>
            <td className="px-6 py-4">{item.frequency}</td>
            <td className="px-6 py-4">{item.status}</td>
            <td className="px-6 py-4">{item.dateJoined}</td>
            <td className="px-6 py-4">{item.noOfTimeContributed}</td>
            <td className="px-6 py-4">{item.lastDateOfContribution}</td>

           
        </tr>
    ))
) : (
    <tr className='mt-9'>
    <td colSpan="4" className='text-center text-1xl '>
        No Customer Available
    </td>
</tr>
)}
</tbody>
);

export default TableComponent;
