import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../utils/axios'; // Import axiosInstance
import { toast } from 'react-toastify'; // Import toast
import image1 from '../img/man (1).png';
import Modal from './modal_customer'; // Import Modal component

const TableComponent = ({ data }) => {
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = (id) => {
      setIsOpen(!isOpen);
      setId(id);   
    };

    return (
        <tbody>
            {isOpen && <Modal toggleModal={toggleModal} isOpen={isOpen} id={id} />}
            {data.length > 0 ? (
                data.map((item, index) => (
                    <tr className='bg-white border-b text-black ' key={index}>
                        <td scope="row" className="flex items-center px-6 py-4  whitespace-nowrap">
                            <img className="w-10 h-10 rounded-full" src={image1} alt="Jese image" />
                            <div className="ps-3">
                                <div className="text-base font-bold">{item.firstname} {item.lastname}</div>
                            </div>
                        </td>
                     
                        <td className="px-6 py-4">{item.phoneNumber}</td>
                        <td className="px-6 py-3">
                            <span className='ml-4 mr-4'><Link to={`/create_customer/${item.phoneNumber}`}><ion-icon name="pencil-outline" /></Link></span>
                            <span><ion-icon onClick={() => toggleModal(item.phoneNumber)} name="trash-outline" /></span>
                        </td>
                        <td className="px-6 py-3">
                            <Link to={`/view_more/${item.phoneNumber}`}><ion-icon name="eye-outline" /></Link>
                        </td>
                        <td className="px-6 py-3">
                            <Link to={`/thriftdetails/${item.phoneNumber}`}><ion-icon name="eye-outline" /></Link>
                        </td>
                        <td className="px-6 py-3">
                            <Link to={`/transactions/${item.phoneNumber}`}><ion-icon name="eye-outline" /></Link>
                        </td>
                     
                    </tr>
                ))
            ) : (
                <tr className='mt-9'>
                    <td colSpan="4" className='text-center text-1xl '>
                        No Customer Available
                    </td>
                </tr>
            )}
        </tbody>
    );
};

export default TableComponent;


