import React from "react";
import Sidebar from "../components/side_bar";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../utils/axios";
import TableComponent from "../components/dashboardtable2";
import GroupComponent from "../components/dashboardtable";
import { NavLink } from "react-router-dom";
import { useAuth } from "../store/Authprovider";
import ProtectedRoute from "./ProtectedRoute";


const Dashboard = () => {
  
  const { user } = useAuth();

  const [totalcustomer, setcustomerdata] = useState("0");
  const [totalgroup, setgroupdata] = useState("0");
  const [amountrecieved, setamountrcv] = useState("0");
  const [amountWithdraw, setamountwith] = useState("0");
  const [customer, setcustomers] = useState([]);
  const [groupdata, setgroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axiosInstance.get(
          `list_merchant_customer?merchantId=${user.merchantId}`
        );
        setcustomers(response1.data.result);
        setcustomerdata(response1.data.result.length);
        const response2 = await axiosInstance.get(
          `list_merchant_thrift_group?merchantId=${user.merchantId}`
        );
        setgroups(response2.data.result);
        setamountrcv(
          response2.data.result
            .reduce(
              (acc, curr) =>
                acc + parseFloat(curr.amountReceived.replace(/,/g, "")),
              0
            )
            .toLocaleString()
        );
        setamountwith(
          response2.data.result
            .reduce(
              (acc, curr) =>
                acc + parseFloat(curr.amountWithdrawal.replace(/,/g, "")),
              0
            )
            .toLocaleString()
        );
        setgroupdata(response2.data.result.length);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const [isActive, setIsActive] = useState(false);
  function toggleMenu() {
    setIsActive(!isActive);
  }

  return (
    <div className="containers">
      <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
      <div className={`main ${isActive ? "active" : ""}`}>
        <div className="topbar">
          <div className="toggle" onClick={toggleMenu}>
            <ion-icon name="menu-outline" />
          </div>

          <div className="search">
            <label>
              <input type="text" placeHolder="Search here" />
              <ion-icon name="search-outline" />
            </label>
          </div>

          <div className="user">
            <img src="assets/imgs/customer01.jpg" alt="" />
          </div>
        </div>

        {/* ======================= Cards ================== */}
        <div className="cardBox">
          <div className="card bg-emerald-100">
            <div>
              <div className="numbers">
                <p>{totalgroup}</p>
              </div>
              <div className="cardName">Total Group</div>
            </div>

            <div className="iconBx">
              <ion-icon name="people-outline" />
            </div>
          </div>

          <div className="card">
            <div>
              <div className="numbers">{totalcustomer}</div>
              <div className="cardName">Total Customers</div>
            </div>

            <div className="iconBx">
              <ion-icon name="person-outline" />
            </div>
          </div>

          <div className="card">
            <div>
              <div className="numbers">N{amountrecieved}</div>
              <div className="cardName">Amount Received</div>
            </div>

            <div className="iconBx">
              <ion-icon name="cash-outline" />
            </div>
          </div>

          <div className="card">
            <div>
              <div className="numbers">N{amountWithdraw}</div>
              <div className="cardName">Amount Withdraw</div>
            </div>

            <div className="iconBx">
              <ion-icon name="cash-outline" />
            </div>
          </div>
        </div>

        {/* ================ Order Details List ================= */}
<div className="details">
       
<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption className="p-5 w-full font-extrabold text-black  text-lg font-semibold text-left rtl:text-right">
            
            <div className="flex justify-between"><p className="text-zippy text-2xl"> Customers</p>
            {totalcustomer > 3 ?<NavLink to="/merchant_customer"  exact> <button className="text-white bg-zippy p-2 rounded-lg">View more</button></NavLink> : ''}
              </div> 
            </caption>
            <thead className="text-sm text-black uppercase  font-extrabold ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Names
                </th>
                <th scope="col" className="px-6 py-3">
                  Address
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Date Created
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            <TableComponent data={customer} />
          </table>
</div>

<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-black  ">
        <div className="flex justify-between"><p className="text-zippy text-2xl">Group</p>
            {totalgroup > 4 ? <NavLink to="/list_thrift"  exact><button className="text-white bg-zippy p-2 rounded-lg">View more</button></NavLink> : ''}
              </div> 
           
        </caption>
        <thead className="text-sm text-black uppercase bg-gray-50 font-extrabold ">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                No of Members
                </th>
                
            </tr>
        </thead>
     
        <GroupComponent data={groupdata} loadingState={''} />
                            
    </table>
</div>



    

          {/* ================= New Customers ================ */}
        </div>

      </div>
    </div>
  );
};

export default Dashboard;
