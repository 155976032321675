import React, { createContext, useContext, useReducer} from "react";


let localStorageState;
if (typeof window !== 'undefined') {
  localStorageState = localStorage.getItem("user");
}



const initialState = localStorageState
? {
    user: JSON.parse(localStorageState),
    islogin:true
  }
: {
  user: null,
  islogin:false
};





const Authreducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { user: action.payload, islogin: true };
    case "LOGOUT":
      localStorage.removeItem("user");
      return { user: null, islogin:false };
    default:
      return state;
  }
  
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Authreducer, initialState);

  const login = (userdata) => {
    dispatch({ type: "LOGIN", payload: userdata });
    return userdata;
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };
  const search = () => {
    dispatch({ type: "SEARCH" });
  };

 

  return (
    <AuthContext.Provider value={{ user: state.user, login, logout,  islogin:state.islogin,  }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

 
  return context;
};
