import './App.css';
import Login from './pages/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Listthrift from './pages/list_thrift';
import Createthrift from './pages/create_thrift';
import Createcustomer from './pages/create_customer';
import Joingroup from './pages/join_group';
import Changepassword from './pages/change_password';
import CustomerWalletBalance from './pages/current_wallet_balance';
import Transactions from './pages/transactions';
import Merchant from './pages/merchant_customer';
import Resetpassword from './pages/reset_password';
import { useAuth } from './store/Authprovider';
import Thriftdetails from './pages/thriftdetails';
import Collectfunds from './pages/collect_funds';
import Withdrawfunds from './pages/withdraw_funds';
import './style.css'
import Viewmore from './pages/view_more';
import ProtectedRoute from './pages/ProtectedRoute';
import Notfound from './pages/not_found';
import Newlogin from './pages/newlogin';
import Otpcode from './pages/otp_code';
import Modal from './components/modal';



function App() {
  const { user } = useAuth();
 
  return (
    <Router>
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/modal' element={<Modal />} />
      <Route path='/reset_password' element={<Resetpassword />} />
      <Route path='/newlogin' element={<Newlogin />} />
      <Route path="*" element={<Notfound />} />
      <Route element={<ProtectedRoute />}>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/list_thrift' element={<Listthrift />} />
        <Route path='/create_thrift/:id' element={<Createthrift />} />
        <Route exact path='/create_thrift' element={<Createthrift />} />
        <Route path='/merchant_customer/' element={<Merchant />} />
        <Route path='/create_customer/:id' element={<Createcustomer />} />
        <Route path='/create_customer' element={<Createcustomer />} />
        <Route path='/change_password' element={<Changepassword />} />
        <Route path='/transactions/:id?' element={<Transactions />} />
        <Route path='/current_wallet_balance/:id?' element={<CustomerWalletBalance />} />
        <Route path='/join_group' element={<Joingroup />} />
        <Route path='/view_more/' element={<Viewmore />} />
        <Route path='/view_more/:id?' element={<Viewmore />} />
        <Route path='/thriftdetails/' element={<Thriftdetails />} />
        <Route path='/thriftdetails/:id?' element={<Thriftdetails />} />
        <Route path='/collect_funds/' element={<Collectfunds />} />
        <Route path='/otp_code/' element={<Otpcode />} />
        <Route path='/withdraw_funds/' element={<Withdrawfunds />} />
      </Route>
    </Routes>
  </Router>
  );
}

export default App;



