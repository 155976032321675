import React from "react";
import Sidebar from "../components/side_bar";
import { useState } from "react";
import { CSVLink } from "react-csv";
import axiosInstance from "../utils/axios";
import TableComponent from "../components/table2";
import { useAuth } from "../store/Authprovider";
import { useEffect } from "react";
import PaginationButtons from '../components/paginationbutton';
import usePagination from '../hooks/use-pagination';

const Merchant = () => {
  const { user } = useAuth();
  const [customerData, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { currentPage, totalPages, paginatedData, nextPage, prevPage, goToPage } = usePagination(customerData, 6);

 

  useEffect(() => {
    const fetchData = async () => {
        try {

          
          if (!user || !user.merchantId) {
            // Handle the case when user or user.merchantId is null or undefined
            // For example, set data to an empty array
            setData([]);
            return;
        }
            const response = await axiosInstance.get(
                `list_merchant_customer?merchantId=${user.merchantId}`
            );

            if (searchTerm) {
                const searchResults = response.data.result.filter((item) =>
                Object.values(item).some((value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
                ))
                //Object.values convert result to this [ola, customer]
                //.some((value) check if typeof value === 'string 
                setData(searchResults);
            } else {
                setData(response.data.result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [searchTerm, user.merchantId, axiosInstance]);

const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
};




  const [isActive, setIsActive] = useState(false);
  function toggleMenu() {
    setIsActive(!isActive);
  }

  return (
    <div className="containers">
      <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
      <div className={`main ${isActive ? "active" : ""}`}>
        <div className="topbar">
          <div className="toggle" onClick={toggleMenu}>
            <ion-icon name="menu-outline" />
          </div>

          <div className="search">
            <label>
              <input placeHolder="Search here"
              name="search"
              type="text"
                value={searchTerm}
                onChange={handleSearchInputChange}
          

              />
              <ion-icon name="search-outline" />
            </label>
          </div>

          <div className="user">
            <img src="assets/imgs/customer01.jpg" alt="" />
          </div>
        </div>

        {/* ================ Order Details List ================= */}
        <div className="detail">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-white bg-zippy ">
              Customers
            </caption>
            <thead className="text-sm text-black uppercase bg-gray-50 font-extrabold ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Names
                </th>
               
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
             
                <th scope="col" className="px-6 py-3">
                  Update/Delete
                </th>
                <th scope="col" className="px-6 py-3">
                  View More
                </th>
                <th scope="col" className="px-6 py-3">
                  View Thrift Details
                </th>
                <th scope="col" className="px-6 py-3">
                  Transactions
                </th>
               
              </tr>
            </thead>

            <TableComponent data={paginatedData}  />
                            <PaginationButtons
                                currentPage={currentPage}
                                totalPages={totalPages}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
          </table>

          {/* ================= New Customers ================ */}
        </div>
      </div>
    </div>
  );
};

export default Merchant;
