import React from 'react';
import image1 from '../img/groups.png';


const TableComponent = ({ data, loading }) => {

    return (
        <tbody>
            {loading ? (
                <tr>
                    <td colSpan="3" className='text-center text-2xl'>
                        &nbsp;
                    </td>
                </tr>
            ) : data.length > 0 ? (
                data.slice(0,4).map((item, index) => (
                    <tr className=' border-b text-black ' key={index}>
                        <td scope="row" className="flex items-center px-6 py-4  whitespace-nowrap">
                            <img className="w-10 h-10 rounded-full" src={image1} alt="Jese image" />
                            <div className="ps-3">
                                <div className="text-base font-bold">{item.groupName}</div>
                            </div>
                        </td>
                        <td className="px-6 py-4">{item.noOfMembers}</td>                      
                    </tr>
                ))
            ) : (
                <tr className='mt-9'>
        <td colSpan="4" className='text-center text-1xl '>
            No Group Available
        </td>
    </tr>
            )}
        </tbody>
    );
};

export default TableComponent;
