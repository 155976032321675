
import React from 'react'
import Sidebar from '../components/side_bar';
import { CSVLink } from "react-csv";

import { useState } from 'react';
import { useEffect } from 'react';
import TableComponent from '../components/table3';
import axiosInstance from '../utils/axios';
import { useAuth } from '../store/Authprovider';
import PaginationButtons from '../components/paginationbutton';
import usePagination from '../hooks/use-pagination';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import image2 from '../img/customer01.jpg'

const List_thrift = () => {
    const { user } = useAuth();
    const [thriftdetail, setthriftdetail] = useState([]);
    const { currentPage, totalPages, paginatedData, nextPage, prevPage, goToPage } = usePagination(thriftdetail, 6);
    const [loading, setLoading] = useState(false); 
    const { id } = useParams()
    const data = id;

  
   
  
    useEffect(() => {
      const fetchData = async () => {
          try {
              if (!data) {
                  return;
              }
              const response1 = await axiosInstance.get(
                `single_merchant_customer?phoneNumber=${data}&merchantId=${user.merchantId}`
        
            );
            setthriftdetail(response1.data.result.thriftDetails);
        
  
          } catch (error) {
              console.error("Error fetching data:", error);
  
          
      }
  }
  
      fetchData();
  }, [data]);
    







    const [isActive, setIsActive] = useState(false);
    function toggleMenu() {
        setIsActive(!isActive);
    }

    return (
        <div className="containers">
            <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
            <div className={`main ${isActive ? 'active' : ''}`}>
                <div className="topbar">
                    <div className="toggle" onClick={toggleMenu}>

                        <ion-icon name="menu-outline" />

                    </div>

                    <div className="search">
                        <label>
                            <input type="text" placeHolder="Search here" />
                            <ion-icon name="search-outline" />
                        </label>
                    </div>

                    <div className="user">
                        <img src={image2} alt="" />
                    </div>
                </div>




                { /* ================ Order Details List ================= */}
                <div className="detail">

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-white bg-zippy ">

        <div className="flex justify-between"><p className="text-white text-2xl">  Thrift Details of {data}</p>
        {data ?<NavLink to="/merchant_customer"  exact>    <h2 className="text-1xl text-white p-1">Back</h2></NavLink> : ''}
              </div> 
        </caption>
        <thead className="text-sm text-black uppercase bg-gray-50 font-extrabold ">
            <tr>
             
                <th scope="col" className="px-6 py-3">
                Group Name
                </th>
                <th scope="col" className="px-6 py-3">
                Amount
                </th>
                <th scope="col" className="px-6 py-3">
                Amount Contributed
                </th>
                <th scope="col" className="px-6 py-3">
               Amount Withdraw
                </th>
                <th scope="col" className="px-6 py-3">
            Default Charge
                </th>

                <th scope="col" className="px-6 py-3">
            Frequency
                </th>
                <th scope="col" className="px-6 py-3">
                    <span>Status</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span>Date joined</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span>Contributed Time</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span>Last Time contributed</span>
                </th>

            </tr>
        </thead>
     
        <TableComponent data={paginatedData} loadingState={loading} />
                           
    </table>
    <PaginationButtons
                                currentPage={currentPage}
                                totalPages={totalPages}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
</div>


                   

                    { /* ================= New Customers ================ */}

                </div>
            </div>
        </div>
    )
}

export default List_thrift