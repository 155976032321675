import React from 'react';



const PaginationButtons = ({ currentPage, totalPages, prevPage, nextPage }) => {
  return (
    <div className='m-9  w-full '>
      <button class = 'bg-zippy mr-2 p-1 rounded-lg text-white ' onClick={prevPage} disabled={currentPage === 1}>Previous</button>
      <span>{`Page ${currentPage} of ${totalPages}`}</span>
      <button class = 'bg-zippy ml-2 p-1 rounded-lg text-white' onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
    </div>
  );
};

export default PaginationButtons;