import React from 'react'
import Sidebar from '../components/side_bar';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../store/Authprovider';
import axiosInstance from '../utils/axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import img2 from './..//img/customer01.jpg'
import { NavLink } from 'react-router-dom';
import '../style.css';


const Create_thrift = () => {
    const { id } = useParams()
    const data = id;
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
   
    const [frequency, setFrequency] = useState({
        contributionfrequency: '', // Initial value
    });
    const [userdetail, setuserdetail] = useState('');
    function toggleMenu() {
        setIsActive(!isActive);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!data) {
                    return;
                }
                const response1 = await axiosInstance.get(
                    `single_merchant_thrift_group?groupId=${data}`
                );
                setuserdetail(response1.data.result)
                
                setFrequency({ contributionfrequency: response1.data.result.contributionFrequency });
            } catch (error) {
                console.error("Error fetching data:", error);

            
        }
    }

        fetchData();
    }, [data]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const groupName = event.target.name.value;
        const contributionFrequency = event.target.cfc.value;
        const merchantId = user.merchantId
     
if(data === undefined){
    try {
        if (groupName === '' || contributionFrequency === '') {
            toast.error('Please fill all the fields');
            return;
        }
        setIsLoading(true);
        const response = await axiosInstance.post('create_merchant_thrift_group', {
            merchantId: merchantId,
            groupName: groupName,
            contributionFrequency: contributionFrequency,
        });
        if (response.data.status_code === '0') {
            toast.success(response.data.message);
            navigate('/list_thrift');
        } else {
            toast.error(response.data.message);
        }
    } catch (error) {
        console.error(error);
        toast.error('An error occurred, Contact Admin');
    }
    finally{
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);  
    }
}
else{
    try {
        if (groupName === '' || contributionFrequency === '') {
            toast.error('Please fill all the fields');
            return;
        }
        setIsLoading(true);
        const response = await axiosInstance.post('update_merchant_thrift_group', {
            merchantId: merchantId,
            groupName: groupName,
            contributionFrequency: contributionFrequency,
            groupId:data
        });
        if (response.data.status_code === '0') {
            toast.success(response.data.message);
            navigate('/list_thrift');
        } else {
            toast.error(response.data.message);
        }
    } catch (error) {
        console.error(error);
        toast.error('An error occurred, Contact Admin');
    }
    finally{
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);  
    }
}
     
    }
    




    return (
        <div className="containers">
            <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
            <div className={`main ${isActive ? 'active' : ''}`}>
                <div className="topbar">
                    <div className="toggle" onClick={toggleMenu}>

                        <ion-icon name="menu-outline" />

                    </div>

                    <div className="search">
                        <label>
                            <input type="text" placeHolder="Search here" />
                            <ion-icon name="search-outline" />
                        </label>
                    </div>

                    <div className="user">
                        <img src={img2} alt="" />
                    </div>
                </div>




                { /* ================ Order Details List ================= */}
                <div className="detail">
                    <div className="recentOrde flex flex-col">

                        <div className="cardHeader bg-zippy mb-5 rounded-t-[20px] flex-grow ">
                            <h2 className="text-2xl text-white p-4">{data?'Update':'Create'} Thrift Group </h2>
                            {data ?<NavLink to="/list_thrift"  exact>    <h2 className="text-1xl text-white p-4">Back</h2></NavLink> : ''}
                        </div>

                        <form onSubmit={handleSubmit} className='p-[20px]' >
                            <div className="grid gap-6  md:grid-cols-2">
                                <div>

                                    <label htmlFor="first_name" className="block mb-2 text-md font-extrabold  text-gray-900 ">Group name</label>
                                    <input type="text" defaultValue={userdetail.groupName} name='name' id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5     dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                </div>


                                <div>
                                    <label
                                        htmlFor="last_name"
                                        className="block mb-2 text-md font-extrabold text-gray-900 "
                                    >
                                        Contribution Frequency
                                    </label>
                                    <select
    name="cfc"
    value={frequency.contributionfrequency}
    onChange={(e) => setFrequency({  contributionfrequency: e.target.value })}
    className="bg-gray-50 text-black border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
>
    <option value='' className="font-bold">
        Choose Frequency
    </option>
    <option value='Daily' className="font-bold">
        Daily
    </option>
    <option value='Weekly' className="font-bold">
        Weekly
    </option>
    <option value='Monthly' className="font-bold">
        Monthly
    </option>
</select>

                                </div>
                            

                            </div>

                            <div className="">

                            </div>
                            <div className="">

                            </div>
                            <div className="flex items-start mb-6">

                            </div>
                            <button
                                disabled={isLoading}
                                type="submit"
                                className="text-white bg-zippy  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2  inline-flex items-center"
                            >
                                {isLoading ? (
                                    <>
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                        Submitting request...
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>

                    </div>

                    { /* ================= New Customers ================ */}

                </div>
            </div>
        </div>
    )
}

export default Create_thrift
