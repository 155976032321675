import React, { useState } from "react";
import { useAuth } from "../store/Authprovider";
import { Navigate, useLocation } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import image1 from '../img/zippylogo.png'
import '../style.css';

const Navlinks = ({ pathname, Navlinkname, iconName  }) => {
 

  const location = useLocation()




  return (
    <>

          <li className={location.pathname === pathname && "bg-[#fff] text-[#69552f] xxx"}>
            <NavLink to={pathname} exact>
              <span className={`icon ${location.pathname === pathname && "text-[#011f60]"}`}>
                <ion-icon name={iconName}  />
              </span>
              <span className={`title ${location.pathname === pathname && "text-[#011f60]"}`}>{Navlinkname}</span>
            </NavLink>
          </li>


         
    </>
  );
};

export default Navlinks;
