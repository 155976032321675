
import React from 'react'
import Sidebar from '../components/side_bar';

import { useState } from 'react';
import { useEffect } from 'react';
import TableComponent from '../components/table';
import axiosInstance from '../utils/axios';
import { useAuth } from '../store/Authprovider';
import PaginationButtons from '../components/paginationbutton';
import usePagination from '../hooks/use-pagination';

const List_thrift = () => {
    const { user } = useAuth();
   
    const [groupData, setData] = useState([]);
      const [searchTerm, setSearchTerm] = useState('');
    const { currentPage, totalPages, paginatedData, nextPage, prevPage, goToPage } = usePagination(groupData, 4);
    const [loading, setLoading] = useState(false); 
   

    useEffect(() => {
        const fetchData = async () => {
            try {

                if (!user || !user.merchantId) {
                    // Handle the case when user or user.merchantId is null or undefined
                    // For example, set data to an empty array
                    setData([]);
                    return;
                }
                const response = await axiosInstance.get(`list_merchant_thrift_group?merchantId=${user.merchantId}`);
                if (searchTerm) {
                    const searchResults = response.data.result.filter((item) =>
                    Object.values(item).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
                    ))
                    //Object.values convert result to this [ola, customer]
                    //.some((value) check if typeof value === 'string 
                    setData(searchResults);
                } else {
                    setData(response.data.result);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [searchTerm, user.merchantId, axiosInstance]);
    
    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    







    const [isActive, setIsActive] = useState(false);
    function toggleMenu() {
        setIsActive(!isActive);
    }

    return (
        <div className="containers">
            <Sidebar isActive={isActive} toggleMenu={toggleMenu} />
            <div className={`main ${isActive ? 'active' : ''}`}>
                <div className="topbar">
                    <div className="toggle" onClick={toggleMenu}>

                        <ion-icon name="menu-outline" />

                    </div>

                    <div className="search">
                        <label>
                        <input placeholder="Search here" name="search" type="text" value={searchTerm} 
                         onChange={handleSearchInputChange} />
                            <ion-icon name="search-outline" />
                        </label>
                    </div>

                    <div className="user">
                        <img src="assets/imgs/customer01.jpg" alt="" />
                    </div>
                </div>




                { /* ================ Order Details List ================= */}
                <div className="detail">

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-white bg-zippy ">
        Groups
           
        </caption>
        <thead className="text-sm text-black uppercase bg-gray-50 font-extrabold ">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                    Merchant ID
                </th>
                <th scope="col" className="px-6 py-3">
                No of Members
                </th>
                <th scope="col" className="px-6 py-3">
                Amount Received
                </th>
                <th scope="col" className="px-6 py-3">
               Amount Withdraw
                </th>
                <th scope="col" className="px-6 py-3">
                Status
                </th>

                <th scope="col" className="px-6 py-3">
            Date Created
                </th>
                <th scope="col" className="px-6 py-3">
                    <span>Update/Delete/Staus</span>
                </th>
            </tr>
        </thead>
     
        <TableComponent data={paginatedData} loadingState={loading} />
                           
    </table>
    <PaginationButtons
                                currentPage={currentPage}
                                totalPages={totalPages}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
</div>


                   

         

                </div>
            </div>
        </div>
    )
}

export default List_thrift